import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import styles from './RTLdesign.module.css';

const RTLDesign = () => {
  // Array of objects to store key elements of RTL Design
  const keyElements = [
    {
      title: 'Behavioral Description',
      description:
        'Designers define the behavior of the digital circuit using behavioral constructs in HDLs. This includes specifying operations, control signals, state machines, and timing constraints to accurately model the intended functionality of the circuit.',
    },
    {
      title: 'Modularity and Hierarchy',
      description:
        'RTL Design promotes modularity and hierarchical organization of the digital system. Designers encapsulate functional units (modules) within the design, allowing for easier management, reusability, and scalability of the overall system.',
    },
    {
      title: 'Synchronous Design',
      description:
        'RTL Design primarily follows a synchronous design methodology, where registers (flip-flops) synchronize the flow of data and control signals across different parts of the circuit. Timing constraints, clock signals, and timing diagrams are essential for ensuring proper synchronization and timing behavior.',
    },
  ];

  const verificationItems = [
    {
      title: 'Simulation:',
      description:
        'Functional verification is performed through simulation, where testbenches generate stimuli to validate the behavior of the RTL design under various scenarios.',
    },
    {
      title: 'Code Coverage:',
      description:
        'Techniques such as code coverage analysis ensure that all parts of the RTL code are exercised during simulation, enhancing verification completeness.',
    },
    {
      title: 'Formal Verification:',
      description:
        'Formal methods are used to formally prove the correctness of the RTL design with respect to specified properties, ensuring that the design meets functional requirements and avoids potential design flaws.',
    },
  ];

  // State to manage selected element for dynamic display
  const [selectedElement, setSelectedElement] = useState(null);

  const handleElementClick = (element) => {
    setSelectedElement(element);
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.heading1}>The Foundation of Digital Circuit Design</span>
      <p>
        RTL Design starts with the specification of the digital system's behavior and functionality.
        Designers use hardware description languages (HDLs) such as Verilog and VHDL to define the operation of registers,
        arithmetic units, control units, and data paths within the system. The design captures how data flows between registers
        and how operations are performed on that data, ensuring the correct behavior of the circuit.
      </p>

      <span className={styles.heading2}>Key Elements of RTL Design</span>
      <div className={styles.flexContainer}>
        <div className={styles.container}>
          {keyElements.map((element, index) => (
            <div
              key={index}
              className={`${styles.element} ${index % 2 === 0 ? styles.even : styles.odd}`}
              onClick={() => handleElementClick(element)}
            >
              <span className={styles.title}>{element.title}</span>
              <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
            </div>
          ))}
        </div>

        {selectedElement && (
          <div className={styles.detailsContainer}>
            <h2>{selectedElement.title}</h2>
            <p>{selectedElement.description}</p>
          </div>
        )}
      </div>

      <span className={styles.heading2}>Verification and Validation</span>
      <span>Verification of RTL designs is critical to ensuring correctness before proceeding to the next stages of design implementation:</span>

      <Swiper
        cssMode={true}
        navigation={true}
        pagination={{ clickable: true }}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className={styles.mySwiper}
    >
        {verificationItems.map((item, index) => (
        <SwiperSlide key={index} className={styles.swiperSlide}>
            <h3 className={styles.swiperTitle}>{item.title}</h3>
            <p className={styles.swiperDescription}>{item.description}</p>
        </SwiperSlide>
        ))}
        </Swiper>
    </div>
  );
};

export default RTLDesign;
