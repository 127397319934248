import React, { useState, useRef, useEffect } from "react";
import RTLImage from "../../assets/RTLImage.jpg";
import DesignVerification from "../../assets/DesignVerification.jpg";
import DFTImage from "../../assets/Testability.jpg";
import PhysicalDesignImage from "../../assets/physicalDesign.jpg";
import PostSilicon from "../../assets/postSilicon.jpg";
import { useNavigate } from 'react-router-dom';
import styles from "./services.module.css";

const Services = () => {
  const navigate = useNavigate(); 
  const services = [
    {
      label: "RTL Designing",
      icon: RTLImage,
      textColor: "black",
      route: "/rtlDesign",
      content: (
        <p>
          <strong>RTL (Register Transfer Level)</strong> Design is a crucial
          phase in the VLSI design process, where the behavior of a digital
          circuit is described using registers, logic gates, and datapaths at a
          high abstraction level. It serves as the bridge between architectural
          design specifications and the implementation of the hardware.
        </p>
      )
    },
    {
      label: "Design Verification",
      icon: DesignVerification,
      textColor: "black",
      route: "/designVerification",
      content: (
        <p>
          In <strong>VLSI design</strong>, errors can have profound
          consequences, ranging from functional failures to costly rework during
          manufacturing. Design Verification aims to identify and resolve
          potential issues early in the design cycle, minimizing risks and
          optimizing time-to-market.
        </p>
      ),
    },
    {
      label: "Physical Design",
      icon: PhysicalDesignImage,
      route: "/physicalDesign",
      textColor: "black",
      content: (
        <p>
          <strong>Physical Design</strong> in VLSI is a crucial stage in the
          semiconductor manufacturing process, where the logical circuit
          representation of a chip is transformed into a physical layout that
          can be fabricated.
        </p>
      ),
    },
    {
      label: "DFT (Design for Testability)",
      icon: DFTImage,
      route: "/dft",
      textColor: "black",
      content: (
        <p>
          In modern VLSI design, <strong>DFT</strong> is essential for
          identifying manufacturing defects, ensuring functional correctness,
          and meeting quality standards.
        </p>
      ),
    },
    
    {
      label: "Post Silicon Validation",
      icon: PostSilicon,
      textColor: "black",
      route: "/postSiliconValidation",
      content: (
        <p>
          <strong>Post-Silicon</strong> Validation is a critical phase in the
          lifecycle of VLSI chips, where the fabricated silicon is tested
          rigorously to ensure functionality, performance, and reliability.
        </p>
      ),
      sideContent: (
        <div>
          <h3>Key Components of Post-Silicon Validation</h3>
          <ul>
            <li>
              <strong>Bring-Up and Initialization:</strong> The initial phase
              involves powering up the fabricated chip and performing basic
              functionality checks.
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(-1);
  const contentRefs = useRef([]);
  const imageRef = useRef(null);
  const readMoreRefs = useRef([]); // Ref array for "Read More" elements

  const handleReadMore = (index) => {
    navigate(services[index].route);  // Use navigate instead of window.location.href
  };

  // Intersection Observer to trigger the animation on scroll
  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      threshold: 0.1, // Trigger when 10% of the element is in view
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.visible); // Add 'visible' class to trigger animation
          observer.unobserve(entry.target); // Stop observing once the animation is triggered
        }
      });
    }, options);

    readMoreRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      readMoreRefs.current.forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let activeIndex = -1;
      contentRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
            activeIndex = index;
          }
        }
      });
      setCurrentIndex(activeIndex);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (imageRef.current) {
      if (currentIndex !== -1 && services[currentIndex]?.icon) {
        imageRef.current.src = services[currentIndex].icon;
        imageRef.current.style.opacity = 1; // Show image
      } else {
        imageRef.current.style.opacity = 0; // Hide image when no content is active
      }
    }

    contentRefs.current.forEach((ref, index) => {
      if (ref) {
        const readMore = ref.querySelector(`.${styles.readMore}`);
  
        if (index === currentIndex) {
          ref.classList.add(styles.active); // Add active class
          ref.classList.remove(styles.blur);
  
          if (readMore) {
            readMore.classList.add(styles.visible); // Show "Read More" with animation
          }
        } else {
          ref.classList.remove(styles.active); // Remove from inactive items
          ref.classList.add(styles.blur);
  
          // if (readMore) {
          //   readMore.classList.remove(styles.visible); // Hide "Read More" when not active
          // }
        }
      }
    });
  }, [currentIndex]);
  
  return (
    <>
      <h2 className={styles.heading}>Our Specialized Engineering Services</h2>
      <div className={styles.wrapper}>
        {/* Display services as an unordered list */}
        <ul className={styles.serviceList}>
          {services.map((service, index) => (
            <li
              key={index}
              className={styles.serviceCard}
              ref={(el) => (contentRefs.current[index] = el)}
            >
              <span
                className={styles.contentHeading}
                style={{ color: service.textColor }}
              >
                {service.label}
              </span>
              <div className={styles.contentWrapper}>
                <p className={styles.truncatedContent}>{service.content}</p>
                <span
                  className={styles.readMore}
                  onClick={() => handleReadMore(index)}
                >
                  Know More
                </span>
              </div>
            </li>
          ))}
        </ul>

        {/* Right-side images */}
        <div className={styles.imageContainer}>
          <img
            ref={imageRef}
            src={""} // Initially blank
            alt="Service Image"
            style={{ opacity: 0, transition: "opacity 0.5s ease" }}
          />
        </div>

      </div>
    </>
  );
};

export default Services;