import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from './navigation.module.css';
import Logo from '../../assets/otgLogo.jpeg';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', gap: '10px' , marginLeft: '30px'}}>
        <img src={Logo} className={styles.navbarBrand} alt="OTG LOGO" />
        <h1 className={styles.responsiveText}>OTG TECHNOLOGIES</h1>
      </div>

      <FontAwesomeIcon
        icon={faBars}
        className={styles.menuIcon}
        onClick={toggleMobileMenu}
      />
      <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.open : ''}`}>
        <li>
          <Link to="home" smooth={true} duration={500} className={styles.navLink} onClick={toggleMobileMenu}>
            HOME
          </Link>
        </li>
        <li>
          <Link to="about" smooth={true} duration={500} className={styles.navLink} onClick={toggleMobileMenu}>
            ABOUT US
          </Link>
        </li>
        <li>
          <Link to="services" smooth={true} duration={500} className={styles.navLink} onClick={toggleMobileMenu}>
            SERVICES
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500} className={styles.navLink} onClick={toggleMobileMenu}>
            CONTACT US
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
