import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import HomePage from './pages/HomePage/HomePage';
import Navbar from './pages/Navigation/Navigation';
import Services from './pages/Services/Services';
import RTLDesign from './pages/RTLDesign/RTLDesign';
import { Element } from 'react-scroll';
import DesignVerification from './pages/DesignVerification/DesignVerification';
import PhysicalDesign from './pages/PhysicalDesign/PhysicalDesign';
import DFT from './pages/DFT/Dft';
import PostSiliconValidation from './pages/PostSilicon/PostSiliconValidation';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Main homepage route with scrollable sections */}
        <Route
          path="/"
          element={
            <div>
              <Element name="home">
                <HomePage />
              </Element>
              <Element name="about">
                <AboutUs />
              </Element>
              <Element name="services">
                <Services />
              </Element>
              <Element name="contact">
                <ContactUs />
              </Element>
            </div>
          }
        />
        {/* Separate route for RTLDesign page */}
        <Route path="/rtlDesign" element={<RTLDesign />} />
        <Route path="/designVerification" element={<DesignVerification />} />
        <Route path="/physicalDesign" element={<PhysicalDesign />} />
        <Route path="/dft" element={<DFT />} />
        <Route path="/postSiliconValidation" element={<PostSiliconValidation />} />
      </Routes>
    </Router>
  );
}

export default App;
