import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import styles from './DesignVerification.module.css';

const DesignVerification = () => {
  // Key Components Array
  const keyComponents = [
    {
      title: 'Functional Verification',
      description: `
        This phase focuses on verifying the functional correctness of the design. Verification engineers use simulation-based techniques, 
        such as testbenches and stimulus generation, to stimulate the design with various input scenarios and verify the correctness of outputs. 
        Techniques like constrained random testing, coverage-driven verification, and formal verification are employed to exhaustively test different aspects of the design.
      `,
    },
    {
      title: 'Timing Verification',
      description: `
        Timing verification ensures that the design meets timing requirements, such as clock frequency and setup/hold times for flip-flops and registers. 
        Static timing analysis (STA) tools are used to analyze timing paths and ensure that critical timing constraints are met across the entire chip.
      `,
    },
    {
      title: 'Power Verification',
      description: `
        Power consumption is a critical concern in modern VLSI designs. Power verification ensures that the design meets power budget constraints 
        and identifies areas for power optimization. Techniques such as power-aware simulation and analysis are employed to estimate and verify power consumption at different operational modes.
      `,
    },
    {
      title: 'Design for Testability (DFT)',
      description: `
        DFT techniques are integrated into the design to facilitate efficient testing during manufacturing. DFT verification ensures that testability features, 
        such as scan chains, built-in self-test (BIST) circuits, and boundary scan cells, function correctly and enable comprehensive testing of the chip.
      `,
    },
  ];

  // Verification Items Array
  const verificationItems = [
    {
      title: 'Simulation',
      description: `
        Functional verification is primarily conducted through simulation using hardware description languages (HDLs) such as Verilog and VHDL.
      `,
    },
    {
      title: 'Formal Verification',
      description: `
        Formal methods use mathematical techniques to prove or disprove the correctness of a design with respect to specifications.
      `,
    },
    {
      title: 'Emulation and FPGA Prototyping',
      description: `
        Hardware emulation and FPGA prototyping platforms enable testing of the design in a more realistic environment, validating its functionality and performance.
      `,
    },
    {
      title: 'Verification IP and Libraries',
      description: `
        Pre-verified IP blocks and libraries are used to accelerate verification of common functionalities, reducing time and effort.
      `,
    },
  ];

  // State to manage selected component for dynamic display
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleComponentClick = (component) => {
    setSelectedComponent(component);
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.heading1}>The Importance of Design Verification</span>
      <p>
        In VLSI design, errors can have profound consequences, ranging from functional failures to costly rework during manufacturing. 
        Design Verification aims to identify and resolve potential issues early in the design cycle, minimizing risks and optimizing time-to-market. 
        By validating the design against specifications and requirements, verification engineers ensure that the chip behaves as expected under 
        different operating conditions and use cases.
      </p>

      <span className={styles.heading2}>Key Components of Design Verification</span>
      <div className={styles.flexContainer}>
        <div className={styles.container}>
          {keyComponents.map((element, index) => (
            <div
              key={index}
              className={`${styles.element} ${index % 2 === 0 ? styles.even : styles.odd}`}
              onClick={() => handleComponentClick(element)}
            >
              <span className={styles.title}>{element.title}</span>
              <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
            </div>
          ))}
        </div>

        {selectedComponent && (
          <div className={styles.detailsContainer}>
            <h2>{selectedComponent.title}</h2>
            <p>{selectedComponent.description}</p>
          </div>
        )}
      </div>

      <span className={styles.heading2}>Verification Methodologies and Tools</span>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={{ clickable: true }}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className={styles.mySwiper}
      >
        {verificationItems.map((item, index) => (
          <SwiperSlide key={index} className={styles.swiperSlide}>
            <h3 className={styles.swiperTitle}>{item.title}</h3>
            <p className={styles.swiperDescription}>{item.description}</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default DesignVerification;
