// PhysicalDesign.js
import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from "./PhysicalDesign.module.css"; // Assume you have styles defined

const PhysicalDesign = () => {
    // Array of objects to store key elements of Physical Design
    const keyElements = [
        {
            title: "Floorplanning",
            description:
                "The initial step involves partitioning the chip area into manageable blocks and arranging them to minimize wire lengths and optimize signal propagation delays. Floorplanning sets the groundwork for subsequent stages.",
        },
        {
            title: "Placement",
            description:
                "Components (cells) from the netlist are placed within the predefined blocks on the chip, taking into account factors such as timing constraints, signal integrity, and thermal considerations. Advanced placement algorithms ensure efficient use of space while meeting design specifications.",
        },
        {
            title: "Routing",
            description:
                "Once components are placed, interconnections (metal wires) must be routed between them to establish electrical connections. Routing algorithms navigate through the complex maze of obstacles while adhering to design rules to minimize area and delay.",
        },
        {
            title: "Optimization",
            description:
                "Iterative optimization techniques are employed throughout the process to refine and enhance the layout. This includes clock tree synthesis for efficient distribution of clock signals and power grid design to ensure stable and efficient power distribution.",
        },
    ];

    const [selectedElement, setSelectedElement] = useState(null);

    const handleElementClick = (element) => {
        setSelectedElement(element);
    };

    return (
        <div className={styles.wrapper}>
            <span className={styles.heading2}>Key Steps in Physical Design</span>
            <div className={styles.flexContainer}>
                <div className={styles.container}>
                    {keyElements.map((element, index) => (
                        <div
                            key={index}
                            className={`${styles.element} ${index % 2 === 0 ? styles.even : styles.odd}`}
                            onClick={() => handleElementClick(element)}
                        >
                            <span className={styles.title}>{element.title}</span>
                            <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
                        </div>
                    ))}
                </div>

                {selectedElement && (
                    <div className={styles.detailsContainer}>
                        <h2>{selectedElement.title}</h2>
                        <p>{selectedElement.description}</p>
                    </div>
                )}
            </div>

        </div>
    )
}

export default PhysicalDesign;
