import React, { useEffect, useRef } from 'react';
import styles from './about.module.css';

const AboutUs = () => {
  const paragraphRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.visible);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    paragraphRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });
  }, []);

  const setRef = (index) => (el) => {
    paragraphRefs.current[index] = el;
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.aboutUs} ${styles.content}`}>
        <h2 className={styles.heading}>Who We Are</h2>
        
          <p
            ref={setRef(0)}
            className={`${styles.paragraph} ${styles.hidden} ${styles.firstParagraph}`}
          >
            OTG Technologies was born in 2022. OTG is an Engineering Design
            Services company dedicated to delivering exceptional results in a
            timely manner while ensuring constant communication with clients
            throughout the project. With a team of skilled designers, OTG
            guarantees high-quality outcomes and comprehensive support, offering
            turnkey solutions from RTL design to GDS, emphasizing optimal PPA
            metrics. Specializing in RTL Design, DFT, Verification, Analog
            Design, and Layout, OTG also focuses on research collaborations with
            universities on neuromorphic designs.
          </p>
          
        
      </div>

      
      <div
            ref={setRef(1)}
            className={`${styles.secondParagraph} ${styles.hidden} ${styles.delay}`}
          >
            
            <div className={styles['content-wrapper']}>
            <h2 className={styles.heading} style={{color:'white'}}>Innovators in Power and Performance Optimization</h2>
              Our extensive experience includes successfully delivering
              error-free silicon in more than 5 tape outs, covering a diverse
              range of technologies from 14nm to 3nm designs. Our expertise in
              advanced process nodes is centered on high-performance networking
              and computing applications, as well as high-performance, low-power
              ASIC designs. Our primary focus is on enhancing power, performance,
              and area targets to provide optimal solutions for our clients.
            </div>
          </div>
    </div>
  );
};

export default AboutUs;
