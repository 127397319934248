import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './homePage.module.css';
import image1 from '../../assets/image1.png';
import image2 from '../../assets/image2.png';
import Button from '../../components/Button/Button';

const HomePage = () => {
  return (
    <div className={styles.homePage}>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: `${styles.customBullet}`,
          bulletActiveClass: `${styles.customBulletActive}`,
        }}
      >
        <SwiperSlide>
          <div className={styles.slideWithImage}>  
            <div className={styles.slideContent}>
              <p className={styles.headingStyles}>Creating Cutting-Edge Design Solutions to Drive Innovation</p>
              <p className={styles.paraStyles}>We develop forward-thinking designs using advanced technology, driving progress by solving modern challenges with creativity and efficiency.</p>
            </div>
            <img src={image1} alt="Physical Design" className={styles.slideImage} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.slideContent}>
            <p className={styles.headingStyles}>Ensuring high-performance, reliable, and manufacturable semiconductor solutions.</p>
            <p className={styles.paraStyles}>Comprehensive VLSI Design Services: Physical Design, RTL, Verification, Post-Silicon Validation, and DFT</p>
            <Button onClick ={()=>{}} label={'Know More'}></Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.slideWithImage}>  
            <div className={styles.slideContent}>
              <h1 className={styles.headingStyles}>Successfully delivered error-free silicon in 5+ tape outs from 14nm to 3nm designs.</h1>
              <p className={styles.paraStyles}>We specialize in optimizing power, performance, and area for high-performance computing, networking, and low-power ASICs.</p>
            </div>
            <img src={image2} alt="Physical Design" className={styles.slideImage2} />
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="swiper-pagination"></div>
    </div>
  );
};

export default HomePage;
