import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from "./PostSiliconValidation.module.css"; // Import your styles

const PostSiliconValidation = () => {
  // Use the created array for key components
  const keyComponentsPostSiliconValidation = [
    {
      title: "Bring-Up and Initialization",
      description:
        "The initial phase involves powering up the fabricated chip and performing basic functionality checks. This includes verifying power distribution, clock signals, and initial communication interfaces to establish basic operational functionality.",
    },
    {
      title: "Functional Validation",
      description:
        "Functional testing involves executing a series of test patterns and stimuli on the chip to verify its functional correctness. Test vectors generated during pre-silicon simulation and additional post-silicon-specific tests are applied to validate the chip's behavior under various operational modes and corner cases.",
    },
    {
      title: "Performance Validation",
      description:
        "Performance testing assesses the chip's speed, throughput, and latency characteristics against design specifications. This includes measuring timing constraints, signal integrity, and overall system performance to ensure optimal operation.",
    },
    {
      title: "Power and Thermal Validation",
      description:
        "Power and thermal testing evaluate the chip's power consumption and heat dissipation characteristics. Techniques such as dynamic power analysis and thermal profiling ensure that the chip operates within specified thermal limits and power budgets.",
    },
    {
      title: "Reliability and Stress Testing",
      description:
        "Reliability testing involves subjecting the chip to stress conditions, including voltage variations, temperature extremes, and environmental factors, to assess its robustness and reliability over its intended lifespan.",
    },
  ];

  const [selectedElement, setSelectedElement] = useState(null);

  const handleElementClick = (element) => {
      setSelectedElement(element);
  };

  return (
    <div className={styles.wrapper}>
            <span className={styles.heading2}>Key Components of Post-Silicon Validation</span>
            <div className={styles.flexContainer}>
                <div className={styles.container}>
                    {keyComponentsPostSiliconValidation.map((element, index) => (
                        <div
                            key={index}
                            className={`${styles.element} ${index % 2 === 0 ? styles.even : styles.odd}`}
                            onClick={() => handleElementClick(element)}
                        >
                            <span className={styles.title}>{element.title}</span>
                            <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
                        </div>
                    ))}
                </div>

                {selectedElement && (
                    <div className={styles.detailsContainer}>
                        <h2>{selectedElement.title}</h2>
                        <p>{selectedElement.description}</p>
                    </div>
                )}
            </div>
        </div>
  );
};

export default PostSiliconValidation;
