import React, { useState } from "react";
import styles from "./dft.module.css"; // Import your styles
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

const DFT = () => {
  // Use the created arrays for key elements and verification items
  const keyElementsDFT = [
    {
      title: "Scan Chains",
      description:
        "Scan chains are a fundamental DFT technique used to facilitate serial access to internal registers within the chip for testing purposes. During test mode, test patterns can be shifted into these registers and their responses captured for analysis, enabling comprehensive testing of sequential logic elements.",
    },
    {
      title: "Built-In Self-Test (BIST)",
      description:
        "BIST incorporates dedicated test circuitry within the chip to autonomously generate test patterns, apply them to the circuitry under test, and analyze the resulting responses. BIST reduces dependency on external test equipment and enhances test coverage for embedded memories, processors, and other complex structures.",
    },
    {
      title: "Boundary Scan (IEEE 1149.1)",
      description:
        "Boundary Scan is a standardized DFT technique defined by IEEE 1149.1 (JTAG) that provides a means to test interconnections (such as PCB traces and chip pins) and to access internal nodes in a chip for testing and debugging purposes. It enables structural testing of the chip's connectivity and facilitates in-system programming and debugging.",
    },
    {
      title: "Design Compiler Insertion",
      description:
        "Design Compiler Insertion involves automatically inserting test logic structures (such as scan chains and BIST controllers) into the RTL (Register Transfer Level) design during synthesis. This ensures seamless integration of DFT features while optimizing the design for area, timing, and power.",
    },
  ];

  const verificationItemsDFT = [
    {
      title: "DFT Simulation",
      description:
        "Simulation-based verification validates the operation of scan chains, BIST circuits, and other DFT features using testbenches and functional verification methodologies.",
    },
    {
      title: "DFT Coverage Analysis",
      description:
        "Coverage-driven verification measures the effectiveness of DFT techniques in detecting faults and achieving targeted test coverage goals across the design.",
    },
  ];

  const [selectedElement, setSelectedElement] = useState(null);

  const handleElementClick = (element) => {
    setSelectedElement(element);
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.heading2}>Key Elements of Design for Testability</span>
      <div className={styles.flexContainer}>
        <div className={styles.container}>
          {keyElementsDFT.map((element, index) => (
            <div
              key={index}
              className={`${styles.element} ${index % 2 === 0 ? styles.even : styles.odd}`}
              onClick={() => handleElementClick(element)}
            >
              <span className={styles.title}>{element.title}</span>
              <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
            </div>
          ))}
        </div>

        {selectedElement && (
          <div className={styles.detailsContainer}>
            <h2>{selectedElement.title}</h2>
            <p>{selectedElement.description}</p>
          </div>
        )}
      </div>

      <span className={styles.heading2}>Verification and Validation</span>
      <span>Verification of RTL designs is critical to ensuring correctness before proceeding to the next stages of design implementation:</span>

      <Swiper
        cssMode={true}
        navigation={true}
        pagination={{ clickable: true }}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className={styles.mySwiper}
    >
        {verificationItemsDFT.map((item, index) => (
        <SwiperSlide key={index} className={styles.swiperSlide}>
            <h3 className={styles.swiperTitle}>{item.title}</h3>
            <p className={styles.swiperDescription}>{item.description}</p>
        </SwiperSlide>
        ))}
        </Swiper>
    </div>
  );
 
};

export default DFT;
